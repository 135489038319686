<template>
  <div class="container">
    <div class="row">
      <div class="mt-90 col-lg-8 col-lg-offset-2">
        <div class="row">
          <div class="login-wrapper crypto">
            <div
              class="
                col-lg-5 col-sm-12
                hidden-sm
                no-padding-left no-padding-right
              "
            >
              <img src="@/assets/crypto-dash/login-img.png" alt="" />
            </div>
            <div class="col-lg-7 col-sm-12">
              <div
                id="login"
                class="login loginpage mt-0 no-pl no-pr pt30 pb30"
              >
                <div class="login-form-header flex align-items-center">
                  <img
                    src="@/assets/crypto-dash/padlock.png"
                    alt="login-icon"
                    style="max-width: 64px"
                  />
                  <div class="login-header">
                    <h4 class="bold">{{authTitle}}</h4>
                    <h4><small>{{authSubtitle}}</small></h4>
                  </div>
                </div>

                <div class="box login">
                  <div class="content-body" style="padding-top: 30px">
                    <router-view />
                  </div>
                </div>

                <p id="nav" class="over-h">
                  <a  v-if="currentPage!='recover'"
                    class="pull-left blue-text"
                    @click="$router.push({ name: 'recover' })"
                    title="Password Lost and Found"
                    >He olvidado mi contraseña</a
                  >
                  <a v-if="currentPage!='sign-up'"
                    class="pull-right blue-text ml-10"
                    @click="$router.push({ name: 'sign-up' })"
                    title="Sign Up"
                    >Crear cuenta</a
                  >
                  <a v-if="currentPage!='sign-in'"
                    class="pull-right blue-text"
                    @click="$router.push({ name: 'sign-in' })"
                    title="Sign Up"
                    >Identificarme</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthView",
  mounted() {
    document.body.classList.add("login-bg");
  },
  destroyed() {
    document.body.classList.remove("login-bg");
  },
  computed: {
    currentPage() {
      return this.$route.name
    },
    authTitle() {
      const texts = {
        "sign-in": "Iniciar sesión",
        "sign-up": "Crear cuenta",
        "recover": "Resetear contraseña"
      }
      return texts[this.currentPage]
    },
    authSubtitle() {
      const texts = {
        "sign-in": "Identifícate con tus datos de acceso",
        "sign-up": "Introduce tus datos para crear una cuenta",
        "recover": "Introduzca su contraseña para recibir una nueva"
      }
      return texts[this.currentPage]
    }
  }
};
</script>
